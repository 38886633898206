var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-mobile is-multiline"},[_c('div',{staticClass:"column is-6"},[_vm._m(0),_vm._m(1),_vm._l((_vm.form.nortification),function(n,index){return _c('div',{key:index,staticClass:"field"},[_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Notification Email","rules":"email||required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nortification[index]),expression:"form.nortification[index]"}],staticClass:"input",class:{ error: errors[0] },attrs:{"type":"text","placeholder":"Add Email"},domProps:{"value":(_vm.form.nortification[index])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.nortification, index, $event.target.value)}}}),_c('span',{staticClass:"Trash"},[(index !== 0)?_c('i',{staticClass:"fas fa-trash",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.form.nortification.splice(index, 1)}}}):_vm._e()]),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),(_vm.form.nortification.length < 10)?_c('button',{staticClass:"button primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.form.nortification.push('')}}},[_vm._v(" Add Email ")]):_vm._e()],2),_c('div',{staticClass:"column is-6"},[_c('label',{staticClass:"label"},[_vm._v("Job Post Expiration Notification Email(s)")]),_vm._l((_vm.form.notifyExpire),function(n,index){return _c('div',{key:index,staticClass:"field"},[_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Expired Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.notifyExpire[index]),expression:"form.notifyExpire[index]"}],staticClass:"input ",class:{ error: errors[0] },attrs:{"type":"text","placeholder":"Add Email"},domProps:{"value":(_vm.form.notifyExpire[index])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.notifyExpire, index, $event.target.value)}}}),_c('span',{staticClass:"Trash"},[(index !== 0)?_c('i',{staticClass:"fas fa-trash",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.form.notifyExpire.splice(index, 1)}}}):_vm._e()]),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),(_vm.form.notifyExpire.length < 10)?_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.form.notifyExpire.push('')}}},[_vm._v(" Add Email ")]):_vm._e()],2)])]),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button info",on:{"click":function($event){_vm.addEmit(),
      _vm.$router.push({
        name: 'description',
        query: { id: _vm.$route.query.id, type: _vm.$route.query.type },
      })}}},[_vm._v(" Back ")]),(_vm.$route.query.id &&
      _vm.$route.query.type !== 'draft' &&
      _vm.$route.query.type !== 'expired'
      )?_c('button',{staticClass:"button primary",on:{"click":function($event){return _vm.update()}}},[_vm._v(" Update ")]):_c('button',{staticClass:"button primary",on:{"click":function($event){return _vm.updateDraft()}}},[_vm._v(" Save and Next ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"label"},[_vm._v(" Notification Email "),_c('span',[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"label"},[_vm._v(" Job Post Notification Email(s) "),_c('span',[_vm._v("*")])])}]

export { render, staticRenderFns }