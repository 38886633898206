<template>
  <div>
    <div class="box">
      <div class="columns is-mobile is-multiline">
        <!-- Nortification email-->
        <div class="column is-6">
          <!-- <<<<<<< HEAD  -->
          <label class="label"> Notification Email <span>*</span></label>
          <!-- ======= -->
          <label class="label">
            Job Post Notification Email(s) <span>*</span></label>
          <!-- >>>>>>> e69b8ec (update design) -->
          <div class="field" v-for="(n, index) in form.nortification" :key="index">
            <div class="control">
              <ValidationProvider name="Notification Email" rules="email||required" v-slot="{ errors }">
                <input type="text" class="input" :class="{ error: errors[0] }" v-model="form.nortification[index]"
                  :placeholder="`Add Email`" />

                <span class="Trash">
                  <i class="fas fa-trash" style="cursor: pointer" @click="form.nortification.splice(index, 1)"
                    v-if="index !== 0"></i>
                </span>
                <span style="color: red; font-size: 12px">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <button type="button" class="button primary" v-if="form.nortification.length < 10"
            @click="form.nortification.push('')">
            Add Email
          </button>
        </div>

        <!-- notifyExpire -->
        <div class="column is-6">
          <label class="label">Job Post Expiration Notification Email(s)</label>
          <div class="field" v-for="(n, index) in form.notifyExpire" :key="index">
            <div class="control">
              <ValidationProvider name="Expired Email" rules="email" v-slot="{ errors }">
                <input type="text" class="input " :class="{ error: errors[0] }" v-model="form.notifyExpire[index]"
                  :placeholder="`Add Email`" />

                <span class="Trash">
                  <i class="fas fa-trash" style="cursor: pointer" @click="form.notifyExpire.splice(index, 1)"
                    v-if="index !== 0"></i>
                </span>
                <span style="color: red; font-size: 12px">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <button type="button" class="button" v-if="form.notifyExpire.length < 10" @click="form.notifyExpire.push('')">
            Add Email
          </button>
        </div>
      </div>
    </div>
    <div class="button-group">
      <button class="button info" @click="
        addEmit(),
        $router.push({
          name: 'description',
          query: { id: $route.query.id, type: $route.query.type },
        })
        ">
        Back
      </button>
      <!-- <button class="button info" v-if="!$route.query.id" @click="addEmit(), $router.push({name: 'description', query: { id: $route.query.id} })">Back</button> -->
      <button v-if="$route.query.id &&
        $route.query.type !== 'draft' &&
        $route.query.type !== 'expired'
        " class="button primary" @click="update()">
        Update
      </button>
      <button v-else class="button primary" @click="updateDraft()">
        Save and Next
      </button>
      <!-- <button class="button primary" v-if="!$route.query.id" @click=" addEmit(), $router.push({name: 'application', query: { id: $route.query.id}})">Save and Next</button> -->
      <!-- <button @click="addEmit()" class="button primary">Save and Next</button> -->
    </div>
  </div>
</template>

<script>
import { UPDATE_DRAFT, SAVE_DRAFT, GET_JOBID, UPDATE_JOB } from "@/graphql/Job";
import apolloClient_Company from "@/services/apollo-client-company";
export default {
  props: ["iget", "getEmail"],
  data: () => ({
    period: null,
    isLoading: true,
    clickJob: false,
    salaries: [],
    selectedJob: [],
    experiences: [],
    languages: [],
    levels: [],
    jobs: [],
    locations: [],
    getjobId: [],
    form: {
      file: "",
      companyId: "",
      title: "",
      job: [""],
      location: [""],
      experience: "",
      jobLevelId: "",
      level: "",
      language: [""],
      salary: "",
      salaryid: true,
      description: "",
      tagIds: [],
      zone: [],
      coverletter: false,
      apply: false,
      uselink: false,
      link: "",
      applied: [],
      nortification: [],
      notifyExpire: [],
      ispublished: false,
      postdate: null,
      closedate: "",
      expiredate: "",
      details: "",
      staffQTY: "",
    },
  }),
  watch: {
    "$route.query.id": {
      async handler(val) {
        if (this.$route.query.id) {
          this.GetJobId();
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.iget) {
      this.form = this.iget;
    }
  },
  methods: {
    async update() {
      try {
        if (this.$route.query.id) {
          let isNotifyEmail = [];
          for (
            let notiemail = 0;
            notiemail < this.form.nortification.length;
            notiemail++
          ) {
            const obj = this.form.nortification[notiemail];
            if (obj !== "") isNotifyEmail.push(obj);
          }
          if (!isNotifyEmail.length) {
            await this.$dialog.check("Please fill notification email...");
            return;
          }
          await apolloClient_Company.mutate({
            mutation: UPDATE_JOB,
            variables: {
              jobId: this.$route.query.id,
              employerId: this.form.companyId,
              title: this.form.title,
              description: this.form.description,
              jobFunctionId: this.form.job,
              workingLocationId: this.form.location,
              jobExperienceId: this.form.experience,
              jobLanguageId: this.form.language,
              jobEducationLevelId: this.form.level,
              salayRangeId: this.form.salary,
              isShowSalary: this.form.salaryid,
              tagIds: this.form.tagIds,
              jobZoneIds: [],
              jobLevelId: this.form.jobLevelId,
              openingDate: this.form.postdate,
              closingDate: this.form.closedate,
              expiredDate: this.form.expiredate,
              isApplyUrl: false, // this.form.uselink,
              isCoverLetter: false, //this.form.coverletter,
              isNotApply: this.form.apply,
              isHideApplyButton: this.form.apply,
              appliedEmails: this.form.applied,
              nortificationEmails: isNotifyEmail,
              notifyExpire: this.form.notifyExpire,
              isPublished: true, //this.form.ispublished,
              image: "", //this.form.file,
              isURL: this.form.link,
              details: "", //this.form.details,
              staffQTY: this.form.staffQTY,
            },
          });
          //  this.$router.back();
          if (this.$route.query.id) {
            this.$router.push({
              name: "application",
              query: { id: this.$route.query.id, type: this.$route.query.type },
            });
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    addEmit() {
      this.$emit("putdata", this.form);
    },
    async updateDraft() {
      let isNotifyEmail = [];
      for (
        let notiemail = 0;
        notiemail < this.form.nortification.length;
        notiemail++
      ) {
        const obj = this.form.nortification[notiemail];
        if (obj !== "") isNotifyEmail.push(obj);
      }
      await apolloClient_Company.query({
        query: UPDATE_DRAFT,
        variables: {
          _id: this.$route.query.id,
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job,
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.tagIds,
          jobZoneIds: null, //this.form.zone,
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: false, // this.form.uselink,
          isCoverLetter: false, //this.form.coverletter,
          isNotApply: this.form.apply,
          isHideApplyButton: "", //this.form.apply,
          appliedEmails: this.form.applied ? this.form.applied : [],
          nortificationEmails: isNotifyEmail,
          notifyExpire: this.form.notifyExpire ? this.form.notifyExpire : [],
          isPublished: true,
          image: "", //this.form.file,
          isURL: this.form.link,
          details: "", //this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });
      this.$emit("putdata", this.form);
      if (this.$route.query.id) {
        this.$router.push({
          name: "application",
          query: { id: this.$route.query.id, type: this.$route.query.type },
        });
      } else {
        this.$router.push({ name: "application" });
      }
    },
    async GetJobId() {
      const res = await apolloClient_Company.query({
        query: GET_JOBID,
        variables: {
          type: this.$route.query.type,
          jobId: this.$route.query.id,
        },
      });

      this.isLoading = false;
      this.getjobId = res.data.editJob;
      this.form.job = this.getjobId.jobFunctionId.map((i) => i._id);
      this.form.language = this.getjobId.jobLanguageId.map((i) => i._id);
      this.form.location = this.getjobId.workingLocationId.map((i) => i._id);
      this.form.experience = this.getjobId.jobExperienceId._id;
      this.form.tagIds =
        this.getjobId.tagIds !== null
          ? this.getjobId.tagIds.map((i) => i._id)
          : null;
      this.form.jobLevelId = this.getjobId.jobLevelId._id
      this.form.level = this.getjobId.jobEducationLevelId._id;
      this.form.salary = this.getjobId.salayRangeId._id;
      this.form.title = this.getjobId.title;
      this.form.apply = this.getjobId.isNotApply;
      this.form.link = this.getjobId.isURL;
      this.form.description = this.getjobId.description;
      this.form.applied = this.getjobId.appliedEmails
        ? this.getjobId.appliedEmails
        : [];
      this.form.nortification = this.getjobId.nortificationEmails
        ? this.getjobId.nortificationEmails
        : [];
      this.form.notifyExpire =
        this.getjobId.notifyExpire !== null ? this.getjobId.notifyExpire : [];
      this.form.postdate = new Date(this.getjobId.openingDate);
      this.form.closedate = new Date(this.getjobId.closingDate);
      this.form.expiredate = new Date(this.getjobId.expiredDate);
      this.form.companyId = this.getjobId.employerId._id;
      this.form.staffQTY = this.getjobId.staffQTY;
      this.form.salaryid = this.getjobId.isShowSalary;
      if (this.form.nortification == "") {
        this.form.nortification = this.getEmail.nortificationEmails
          ? this.getEmail.nortificationEmails
          : [];
        this.form.notifyExpire = this.getEmail.notifyExpire
          ? this.getEmail.notifyExpire
          : [];
      }
    },
  },
};
</script>

<style></style>
