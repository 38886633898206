import gql from 'graphql-tag'

export const JOB_DELETE = gql`
mutation(
  $jobId:ID
  $type:String
){
deleteJob(jobId:$jobId type:$type)
}
`

export const JOB_LIST =gql`
query(
  $type:String
	$search:String
	$page:Int
	$perPage:Int
){
  getJobList(type:$type search:$search page:$page perPage:$perPage)
  {
		JobList{
    _id
    title
    isClick
    openingDate
    expiredDate
    isDraft
  }
		jobCount
	}
}

`

export const POST_JOB = gql `
mutation (
    $title: String!
    $description: String!
    $jobFunctionId: [ID]!
    $jobExperienceId: ID!
    $jobLanguageId: [ID]!
    $jobEducationLevelId: ID!
    $salayRangeId: ID!
    $isShowSalary: Boolean!
    $tagIds: [ID]
    $jobZoneIds: [ID]
    $jobLevelId:ID
    $workingLocationId: [ID]!
    $openingDate: Date!
    $closingDate: Date!
    $expiredDate: Date!
    $isApplyUrl: Boolean!
    $isCoverLetter: Boolean!
    $isNotApply: Boolean!
    $appliedEmails: [String]
    $nortificationEmails: [String]
    $isPublished: Boolean!
    $image: String
    $isURL: String
    $staffQTY: String
    $details: String
    $notifyExpire:[String]
    $draftId:ID
  ) {
    addJob(info: {title: $title, description: $description, jobFunctionId: $jobFunctionId, jobExperienceId: $jobExperienceId, jobLanguageId: $jobLanguageId, jobEducationLevelId: $jobEducationLevelId, salayRangeId: $salayRangeId, isShowSalary: $isShowSalary, workingLocationId: $workingLocationId, tagIds: $tagIds, jobZoneIds: $jobZoneIds, openingDate: $openingDate, closingDate: $closingDate, expiredDate: $expiredDate, isApplyUrl: $isApplyUrl, isCoverLetter: $isCoverLetter, isNotApply: $isNotApply, appliedEmails: $appliedEmails, nortificationEmails: $nortificationEmails, isPublished: $isPublished, image: $image, isURL: $isURL, details: $details, staffQTY: $staffQTY
    notifyExpire:$notifyExpire,jobLevelId:$jobLevelId draftId:$draftId
    }) {
      employerId {
        _id
      }
      title
      description
      jobFunctionId {
        _id
      }
      jobExperienceId {
        _id
      }
      jobLanguageId {
        _id
      }
      jobEducationLevelId {
        _id
      }
      salayRangeId {
        _id
      }
      isShowSalary
      tagIds {
        _id
      }
      jobZoneIds {
        _id
      }
      jobLevelId{
        _id
      }
      workingLocationId {
        _id
      }
      openingDate
      closingDate
      expiredDate
      isApplyUrl
      isCoverLetter
      isNotApply
      appliedEmails
      nortificationEmails
      isPublished
      image
      isURL
      staffQTY
      details
    }
  }

`
export const SAVE_DRAFT =gql `
mutation (
    $title: String
    $description: String
    $jobFunctionId: [ID]
    $jobExperienceId: ID
    $jobLanguageId: [ID]
    $jobEducationLevelId: ID
    $salayRangeId: ID
    $isShowSalary: Boolean
    $tagIds: [ID]
    $jobZoneIds: [ID]
    $jobLevelId:ID
    $workingLocationId: [ID]
    $openingDate: Date
    $closingDate: Date
    $expiredDate: Date
    $isApplyUrl: Boolean
    $isCoverLetter: Boolean
    $isNotApply: Boolean
    $appliedEmails: [String]
    $nortificationEmails: [String]
    $isPublished: Boolean
    $image: String
    $isURL: String
    $staffQTY: String
    $details: String
    $notifyExpire: [String]
  ) {
    saveDraft(info: {
      title: $title,
      description: $description, jobFunctionId: $jobFunctionId, jobExperienceId: $jobExperienceId, jobLanguageId: $jobLanguageId, jobEducationLevelId: $jobEducationLevelId, salayRangeId: $salayRangeId, isShowSalary: $isShowSalary, workingLocationId: $workingLocationId, tagIds: $tagIds, jobZoneIds: $jobZoneIds, openingDate: $openingDate, closingDate: $closingDate, expiredDate: $expiredDate, isApplyUrl: $isApplyUrl, isCoverLetter: $isCoverLetter, isNotApply: $isNotApply, appliedEmails: $appliedEmails, nortificationEmails: $nortificationEmails, isPublished: $isPublished, image: $image, isURL: $isURL, details: $details, staffQTY: $staffQTY, 
      notifyExpire: $notifyExpire jobLevelId:$jobLevelId}) 
      {
        _id
        title
        description
      }
  
}
`
export const UPDATE_DRAFT = gql`
mutation (
  $_id:ID!
$title: String
$description: String
$jobFunctionId: [ID]
$jobExperienceId: ID
$jobLanguageId: [ID]
$jobEducationLevelId: ID
$salayRangeId: ID
$isShowSalary: Boolean
$tagIds: [ID]
$jobZoneIds: [ID]
$jobLevelId:ID
$workingLocationId: [ID]
$openingDate: Date
$closingDate: Date
$expiredDate: Date
$isApplyUrl: Boolean
$isCoverLetter: Boolean
$isNotApply: Boolean
$appliedEmails: [String]
$nortificationEmails: [String]
$isPublished: Boolean
$image: String
$isURL: String
$staffQTY: String
$details: String
$notifyExpire: [String]
) {
updateDraft(info: {
      _id:$_id
  title: $title,
  description: $description, jobFunctionId: $jobFunctionId, jobExperienceId: $jobExperienceId, jobLanguageId: $jobLanguageId, jobEducationLevelId: $jobEducationLevelId, salayRangeId: $salayRangeId, isShowSalary: $isShowSalary, workingLocationId: $workingLocationId, tagIds: $tagIds, jobZoneIds: $jobZoneIds, openingDate: $openingDate, closingDate: $closingDate, expiredDate: $expiredDate, isApplyUrl: $isApplyUrl, isCoverLetter: $isCoverLetter, isNotApply: $isNotApply, appliedEmails: $appliedEmails, nortificationEmails: $nortificationEmails, isPublished: $isPublished, image: $image, isURL: $isURL, details: $details, staffQTY: $staffQTY, 
  notifyExpire: $notifyExpire jobLevelId:$jobLevelId}) 
  
}

`
export const UPDATE_JOB = gql`
mutation (
  $jobId: ID!, 
  $title: String!, 
  $description: String!,
  $jobFunctionId: [ID]!, 
  $jobExperienceId: ID!,
  $jobLanguageId: [ID]!,
  $jobEducationLevelId: ID!,
  $salayRangeId: ID!,
  $isShowSalary:Boolean!
  $tagIds: [ID], 
  $jobZoneIds: [ID],
  $jobLevelId:ID
  $workingLocationId:[ID]!,
$openingDate: Date!, $closingDate: Date!,$expiredDate:Date! $isApplyUrl: Boolean!, $isCoverLetter: Boolean!, $isNotApply:Boolean!,
  $appliedEmails: [String]!
$nortificationEmails: [String]!
$isPublished:Boolean!
  $image:String
$isHideApplyButton:Boolean
$isURL:String
$details: String
$staffQTY:String
$notifyExpire:[String]
$aType: String
)
{
updateJob(info: {
  jobId: $jobId,
  title: $title, 
  description: $description, 
  jobFunctionId: $jobFunctionId, 
  jobExperienceId: $jobExperienceId, 
  jobLanguageId: $jobLanguageId,
  jobEducationLevelId: $jobEducationLevelId,
  salayRangeId: $salayRangeId, 
  isShowSalary:$isShowSalary,
  tagIds: $tagIds, 
  jobZoneIds: $jobZoneIds, 
  jobLevelId:$jobLevelId
  workingLocationId:$workingLocationId,
  openingDate: $openingDate,
  closingDate: $closingDate, 
  expiredDate:$expiredDate
  isApplyUrl: $isApplyUrl,
  isCoverLetter: $isCoverLetter, 
  isNotApply: $isNotApply,
  appliedEmails: $appliedEmails,
  isHideApplyButton:$isHideApplyButton            nortificationEmails:$nortificationEmails
    isPublished:$isPublished
  image:$image
  isURL:$isURL
  details:$details
  staffQTY:$staffQTY
  notifyExpire:$notifyExpire
  aType:$aType
}) {
  _id
  employerId {
    _id
  }
  title
  description
  jobFunctionId {
    _id
  }
  jobExperienceId {
    _id
  }
  jobLanguageId {
    _id
  }
  jobEducationLevelId {
    _id
  }
  salayRangeId {
    _id
  }
  isShowSalary
  tagIds {
    _id
  }
  jobZoneIds {
    _id
  }
  jobLevelId{
    _id
  }
  workingLocationId{
    _id
  }
  openingDate
  closingDate
  expiredDate
  isApplyUrl
  isCoverLetter
  isNotApply
  appliedEmails
  nortificationEmails
  isPublished
   image
  isURL
  staffQTY
  details
}
}
`

export const GET_JOBID = gql`

query(
    $type:String
    $jobId:ID
  ){
    editJob(type:$type jobId:$jobId){
      employerId {
        _id
        logo
        companyName
      }
      title
      description
      jobFunctionId {
        _id
        name
      }
      jobExperienceId {
        _id
      }
      jobLanguageId {
        _id
      }
      jobEducationLevelId {
        _id
      }
      salayRangeId {
        _id
        name
      }
      isShowSalary
      tagIds {
        _id
      }
      jobZoneIds {
        _id
      }
      workingLocationId {
        _id
        name
      }
      jobLevelId{
        _id 
        name
      }
      openingDate
      closingDate
      expiredDate
      isApplyUrl
      isCoverLetter
      isNotApply
      appliedEmails
      nortificationEmails
      notifyExpire
      isPublished
      image
      isURL
      staffQTY
      details
    }
  }
`